<template>
  <div class="lineCode">
    <section class="main">
      <BarcodePopup :isPop="false" :isLineCode="true" />
    </section>
  </div>
</template>

<script>
import BarcodePopup from '@/components/BarcodePopup.vue';

export default {
  name: 'LineCode',
  components: {
    BarcodePopup,
  },
  data() {
    return {};
  },
};
</script>
